<template>
  <div class="error-box background">
    <div class="py-16">
      <div class="text-center">
        <h1 class="error-title error--text">404</h1>
        <h3 class="text-uppercase my-8 error-subtitle">Page not found!</h3>
        <v-btn color="info" href="/">Go to Home</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.error-box {
  height: 100%;
  width: 100%;
  position: fixed;
}
.error-title {
  font-size: 210px;
  font-weight: 800;
  text-shadow: 4px 4px 0 #fff, 6px 6px 0 #343a40;
  line-height: 210px;
}

@media (max-width: 991px) {
  .error-title {
    font-size: 120px;
    line-height: 120px;
  }
}

@media (max-width: 767px) {
  .error-title {
    font-size: 40px;
    line-height: 40px;
  }
}
</style>
